/* pt-sans-regular - latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./pt-sans-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-v12-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-v12-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-v12-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./pt-sans-v12-latin-regular.svg#PTSans")
      format("svg"); /* Legacy iOS */
}
/* pt-sans-italic - latin */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./pt-sans-v12-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-v12-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-v12-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-v12-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-v12-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./pt-sans-v12-latin-italic.svg#PTSans")
      format("svg"); /* Legacy iOS */
}
/* pt-sans-700 - latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./pt-sans-v12-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-v12-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-v12-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-v12-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-v12-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./pt-sans-v12-latin-700.svg#PTSans")
      format("svg"); /* Legacy iOS */
}
/* pt-sans-700italic - latin */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./pt-sans-v12-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-v12-latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-v12-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-v12-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-v12-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./pt-sans-v12-latin-700italic.svg#PTSans")
      format("svg"); /* Legacy iOS */
}
/* pt-sans-caption-regular - latin */
@font-face {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  src: url("./pt-sans-caption-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-caption-v13-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-caption-v13-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-caption-v13-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-caption-v13-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./pt-sans-caption-v13-latin-regular.svg#PTSansCaption") format("svg"); /* Legacy iOS */
}
/* pt-sans-caption-700 - latin */
@font-face {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 700;
  src: url("./pt-sans-caption-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-caption-v13-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-caption-v13-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-caption-v13-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-caption-v13-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./pt-sans-caption-v13-latin-700.svg#PTSansCaption") format("svg"); /* Legacy iOS */
}
/* pt-sans-narrow-regular - latin */
@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 400;
  src: url("./pt-sans-narrow-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-narrow-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-narrow-v12-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-narrow-v12-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-narrow-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./pt-sans-narrow-v12-latin-regular.svg#PTSansNarrow") format("svg"); /* Legacy iOS */
}
/* pt-sans-narrow-700 - latin */
@font-face {
  font-family: "PT Sans Narrow";
  font-style: normal;
  font-weight: 700;
  src: url("./pt-sans-narrow-v12-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./pt-sans-narrow-v12-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./pt-sans-narrow-v12-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./pt-sans-narrow-v12-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./pt-sans-narrow-v12-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./pt-sans-narrow-v12-latin-700.svg#PTSansNarrow") format("svg"); /* Legacy iOS */
}
